<template>
    <SectionLayout>
        <v-col cols="12" class="ma-0 pa-0">
        <v-row justify="center" class="py-5">
            <h1 class="display-1 font-weight-light">Create new account</h1>
        </v-row>
        <v-row justify="center" class="py-5">
            <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <v-card elevation="6" class="px-10 pb-5 pt-8" v-show="displayInputForm">
                    <v-form v-model="createAccountForm" ref="createAccountFormRef" @submit="validateCreateAccount" onSubmit="return false;" @keyup.enter.native="validateCreateAccount">
                        <v-text-field
                            v-model=name
                            label="Name (first name, nickname, or alias)"
                            :rules="nameRules"
                            validate-on-blur
                            color="blue"
                            required
                            hint="What should we call you?"
                            autofocus
                            outlined
                        >
                                            <template v-slot:prepend>
                        <font-awesome-icon icon="user" fixed-width class="mt-1"/>
                    </template>

                        </v-text-field>
                        <v-text-field
                            ref="emailInputRef"
                            v-model=email
                            label="Email"
                            :rules="emailRules"
                            validate-on-blur
                            color="blue"
                            required
                            hint="What email address can we use to reach you?"  type="text"
                            outlined
                        >
                        <!-- v-on:keyup.enter="$event.target.nextElementSibling.focus()" -->

                    <template v-slot:prepend>
                        <font-awesome-icon icon="envelope" fixed-width class="mt-1"/>
                    </template>
                        </v-text-field>

                                            <v-checkbox
                        v-model="isAgreeToTermsChecked"
                        :rules="agreeToTermsRules"
                        color="blue">
                        <template v-slot:label>
                            <div>
                            I agree to the
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                <a
                                    target="_blank"
                                    href="https://cryptium.com/notice/terms/"
                                    @click.stop
                                    v-on="on"
                                >
                                    Terms of Use
                                </a>
                                </template>
                                Opens in new window
                            </v-tooltip>
                            and
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                <a
                                    target="_blank"
                                    href="https://cryptium.com/notice/privacy/"
                                    @click.stop
                                    v-on="on"
                                >
                                    Privacy Policy
                                </a>
                                </template>
                                Opens in new window
                            </v-tooltip>
                            </div>
                        </template>
                    </v-checkbox>
                        <v-row justify="center">
                            <v-card-actions>
                                <v-btn elevation="6" class="blue white--text" @click="createAccount" :disabled="!createAccountForm">
                                    <font-awesome-icon icon="check" fixed-width/>
                                    <span class="ml-2">Create</span>
                                </v-btn>
                            </v-card-actions>
                        </v-row>
                    </v-form>
                </v-card>

                <v-card elevation="6" class="px-10 pb-5 pt-8" v-show="error">
                    <v-row justify="center" class="pb-5">
                        <p class="headline font-weight-light text-center red--text">We had trouble sending you an email</p>
                    </v-row>
                    <v-row justify="center" class="pb-5">
                        <v-btn elevation="6" class="blue white--text" @click="editForm">
                            <span class="ml-2">Try again</span>
                        </v-btn>
                    </v-row>
                </v-card>

                <v-card elevation="6" class="px-10 pb-5 pt-8" v-show="emailVerification">
                    <v-row justify="center" class="">
                        <p class="headline font-weight-light text-center">Look for our email and click the link</p>
                    </v-row>
                    <v-row justify="center" class="py-5" v-show="isResendEmailReady">
                        <v-btn elevation="6" class="blue white--text" @click="editForm">
                            <span class="ml-2">Didn't get the email?</span>
                        </v-btn>
                    </v-row>
                </v-card>

            </v-col>
        </v-row>
        </v-col>
    </SectionLayout>
</template>

<script>
import { mapState } from 'vuex';
import { isValidName, isValidEmail, compact } from '@/sdk/input';
import SectionLayout from '@/components/SectionLayout.vue';

export default {
    components: {
        SectionLayout,
    },
    data() {
        return {
            createAccountForm: null,
            name: null,
            email: null,
            isAgreeToTermsChecked: false,
            displayInputForm: true,
            emailVerification: false,
            isResendEmailReady: false,
            nameRules: [
                (v) => !!v || 'Name is required',
                (v) => !v || isValidName(compact(v)) || 'Name is required',
            ],
            emailRules: [
                (v) => !!v || 'Email address is required',
                (v) => !v || isValidEmail(v) || 'Email address is required',
            ],
            agreeToTermsRules: [
                (v) => !!v || 'You must agree to the terms of service',
            ],
            error: false,
            interactionId: null,
        };
    },

    computed: {
        ...mapState({
            isReady: (state) => state.isReady,
            session: (state) => state.session,
        }),
        isAuthenticated() {
            return this.session.isAuthenticated;
        },
    },

    watch: {
        isReady(value, oldValue) {
            if (value && !oldValue) {
                this.init();
            }
        },
    },

    methods: {
        init() {
            if (this.isAuthenticated) {
                this.$router.push('/dashboard');
            }
        },
        editForm() {
            this.error = false;
            this.displayInputForm = true;
            this.emailVerification = false;
            this.$nextTick(() => this.$refs.emailInputRef.focus());
        },
        async createAccount() {
            this.error = false;
            console.log('createAccount');
            const request = {
                name: this.name,
                email: this.email,
                agreeToTerms: this.isAgreeToTermsChecked,
                interactionId: this.interactionId, // will be present if user arrives from an existing interaction, such as clicking on a specific link to get started and we can use this to redirect the user to an appropriate location after account is created
            };
            const response = await this.$store.dispatch('createAccount', request);
            console.log('createAccount response: %o', response);
            const { isSent } = response;
            if (isSent) {
                this.verifyEmail();
            } else {
                this.displayError();
            }
        },
        validateCreateAccount() {
            if (this.$refs.createAccountFormRef.validate()) {
                this.createAccount();
            }
        },
        verifyEmail() {
            this.displayInputForm = false;
            this.emailVerification = true;
            this.error = false;
            const waitBeforeFirstCheckStatus = 10 * 1000; // 10 seconds
            setTimeout(() => { this.isResendEmailReady = true; }, waitBeforeFirstCheckStatus);
        },
        displayError() {
            this.error = true;
            this.displayInputForm = false;
            this.emailVerification = false;
        },
    },

    created() {
        console.log('created');
        this.interactionId = this.$route.query.i;
    },

    mounted() {
        console.log('mounted');
        if (this.isReady) {
            this.init();
        }
    },
};
</script>
